import axios from "axios";
import env from "../config";
import { setNotification, useNotifcation } from "../context/notification";
import { ReqMethods, ResponseStatus } from "../enums";

const BASE_URL = `${env.API_URL}/v1`;

function useServiceHandler() {
  // const [, dispatch] = useNotifcation();
  // const Logout = useLogout();

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        console.log("API - RESPONSE", res, toaster, message, error);
        // toaster;

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          return { error: err.response?.data || err };
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message;

        // toaster;
        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = "useAuth()";
  const serviceHandler = useServiceHandler();

  const CallService = (
    method,
    path,
    { query, payload, token = false, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token)
      config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    category: {
      get: (options) => CallService(GET, `${BASE_URL}/menu_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/menu_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/menu_category/filter`, options),
    },
    resturant: {
      get: (options) => CallService(GET, `${BASE_URL}/resturant`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/resturant`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/resturant/filter`, options),
    },
    menu: {
      get: (options) => CallService(GET, `${BASE_URL}/menu`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/menu`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/menu/filter`, options),
    },
  };

  return APIs;
}
const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    console.log(file);
    form.append("image", file, file.name);
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    });
  };
};

export { useAppServices, useCallService, useUploadImage };
