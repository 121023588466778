// import logo from "./logo.svg";
// import "./Aframe.css";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import burger from "../assets/burger.gltf";
import { useEffect, useState } from "react";
import { useAppServices } from "../hook/services";
import "./style.css";
import LeftIcon from "../assets/left.png";
import RightIcon from "../assets/rightarrow.png";
import marker from "../assets/pattern-markers.patt";
import MDTypography from "../components/MDTypography";
import CollapseList from "./components/CollapseList";
import { List, ListItemButton, ListItemText } from "@mui/material";

function Aframe() {
  const { id } = useParams();
  const AppService = useAppServices();
  const [resturant, setresturant] = useState({});
  const [categories, setcategories] = useState([]);
  const [menus, setmenus] = useState([]);
  const [ActiveItem, setActiveItem] = useState({
    main: false,
    child: false,
  });
  const [SelectedMenus, setSelectedMenus] = useState([
    "https://walletcampaigns.fra1.digitaloceanspaces.com/design/logo/image-1686060846304",
    "https://walletcampaigns.fra1.digitaloceanspaces.com/design/logo/image-1686163142818",
  ]);
  const handleClick = ({ type, value }) => {
    setActiveItem({
      main: type == "main" ? value : ActiveItem.main,
      child: type == "child" ? value : false,
    });
  };
  const handleMenu = (id) => {
    alert("dj");
    // const temp = [];
    // menus.forEach((element) => {
    //   const check = element.category.filter((o) => o.value === id);
    //   if (check.length) {
    //     temp.push(element);
    //   }
    // });
    // console.log(temp, "temp");

    // setSelectedMenus(temp);
  };

  const Getresturant = async () => {
    const { response } = await AppService.resturant.get({
      query: `_id=${id}`,
    });
    console.log(response, "response");
    if (response) {
      if(response.data[0]?.design){

        ChangeVaribales(response.data[0]?.design);
      }
      getCategories(response.data[0]?._id);
      getMenu();
      setresturant(response.data[0]);
    }
  };
  const getCategories = async (resturant_id) => {
    const { response } = await AppService.category.get({
      query: `resturant_id=${resturant_id}`,
    });
    console.log(response, "categories");

    if (response) setcategories(response.data);
  };
  const getMenu = async () => {
    const { response } = await AppService.menu.filter();
    console.log(response, "menus");

    if (response) setmenus(response.data);
  };
  const ChangeVaribales = (design) => {
    document.documentElement.style.setProperty(
      "--scan-app-topbar-bg",
      design?.background_colors?.topbar
    );
    document.documentElement.style.setProperty(
      "--scan-app-footer-bg",
      design?.background_colors?.footer
    );
    document.documentElement.style.setProperty(
      "--scan-app-sidbar-bg",
      design?.background_colors?.sidebar
    );
    document.documentElement.style.setProperty(
      "--scan-app-listitem-bg",
      design?.background_colors?.sidebar_list
    );
    // document.documentElement.style.setProperty('--scan-app-listitem-active-bg', design?.background_colors?.jjjjj);
    document.documentElement.style.setProperty(
      "--scan-app-buttons-bg",
      design?.background_colors?.buttons
    );
    document.documentElement.style.setProperty(
      "--scan-app-topbar-text",
      design?.text_colors?.topbar
    );
    document.documentElement.style.setProperty(
      "--scan-app-footer-text",
      design?.text_colors?.footer
    );
    document.documentElement.style.setProperty(
      "--scan-app-listitem-text",
      design?.text_colors?.sidebar_list
    );
    document.documentElement.style.setProperty(
      "--scan-app-buttons-text",
      design?.text_colors?.buttons
    );
  };
  const onLoad = () => {
    // getCategories();
    // setTimeout(() => {
    //   alert('dd')
    //   const myModel= document.getElementById("myModel")
    //    myModel.removeAttribute("gltf-model");
    //    myModel.setAttribute("gltf-model", "#gltf-model");
    // }, 10000);
    Getresturant();
    // getMenu()
  };
  // const next = () => {
  //   // getCategories();
  //   // alert("d");
  //   // getMenu()
  // };

  useEffect(onLoad, []);
  return (
    <div className="App">
      {/* <a-scene
        embedded
        arjs="debugUIEnabled: false;"
        vr-mode-ui="enabled: false"
      >
        <a-assets>
          <a-asset-item
            id="gltf-model"
            src={
              SelectedMenus[0]?.image ||
              "https://walletcampaigns.fra1.digitaloceanspaces.com/design/logo/image-1686060846304"
            }
          ></a-asset-item>
        </a-assets>
        <a-marker preset="custom">
          <a-entity id="main_entity" Scale=".2 .2 .2">
            <a-entity
              id="myModel"
              gltf-Model="#gltf-model"
              Scale={"-2 2 2"}
              gestureDetector
            ></a-entity>
          </a-entity>
        </a-marker>
        <a-entity camera></a-entity>
      </a-scene> */}
      <div style={{ position: "fixed", top: "45vh", left: "3px" }}>
        <img src={LeftIcon} className="previousBtn" />
      </div>
      <div
        style={{
          position: "fixed",
          top: "45vh",
          right: "3px",
        }}
      >
        <img src={RightIcon} className="nextBtn" />
      </div>
      {/* <div className="controller">
        <div className="controller_btn">
          <button className="previousBtn">Previous</button>
          <button className="nextBtn">Next</button>
        </div>
      </div> */}
      <div className="nav_warpper">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark scan-app-topbar">
          <div className="container ">
            <img src={resturant?.logo} alt="logo" width={50} />
            <b className="topbar-color" style={{ color: "white" }}>
              {resturant?.name}
            </b>
            <button
              className=" navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="offcanvas offcanvas-end scan-app-sidebar"
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header justify-content-flex-start">
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={resturant?.logo}
                    alt="logo"
                    width={50}
                    style={{ marginRight: "1rem" }}
                  />
                </div>
              </div>
              <div className="offcanvas-body bg-transparent">
                <ul
                  className="list-group bg-transparent "
                  id="resturant-category"
                >
                  <List sx={{ width: "100%", maxWidth: 360 }}>
                    <ListItemButton
                      id="all-menus"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() =>
                        handleClick({ type: "main", value: "all" })
                      }
                      className={
                        ActiveItem?.main == "all" && ActiveItem?.child == false
                          ? "scan-app-sidebar-list-active list-button"
                          : "scan-app-sidebar-list list-button"
                      }
                    >
                      <ListItemText primary={"All"} />
                    </ListItemButton>
                  </List>

                  {categories.map((category) => (
                    <CollapseList
                      category={category}
                      menus={menus}
                      ActiveItem={ActiveItem}
                      handleActive={handleClick}
                    />
                  ))}
                  {/* <div className="accordion" id="accordionExample">
                    {categories.map((category) => (
                      <div class="accordion-item bg-transparent ">
                        <h2
                          className="accordion-header"
                          id="headingOne"
                          // onClick={() => handleMenu(category._id)}
                        >
                          <button
                            className="accordion-button scan-app-sidebar-list bg-transparent"
                            type="button"
                            category-id={category._id}
                            data-bs-toggle="collapse"
                            data-bs-target={`#target_${category._id}`}
                            aria-expanded={false}
                            aria-controls={`target_${category._id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`target_${category._id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          {menus.map((menu) => {
                            const check = menu.category.filter(
                              (o) => o.value === category._id
                            );
                            if (check.length) {
                              return (
                                <>
                                  <div
                                    menu-image={menu.image}
                                    class="accordion-body"
                                    style={{
                                      color:
                                        resturant?.design?.text_colors
                                          ?.sidebar_list,
                                    }}
                                  >
                                    {menu.name}
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    ))}
                  </div> */}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div
        className="scan-app-footer"
        style={{
          width: "100vw",
          minHeight: "60px",
          height: "auto",
          position: "fixed",
          bottom: "0px",
          left: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              wordBreak: "break-all",
              padding: "7px 25px",
              textAlign: "center",
            }}
          >
            <span className="text-white scan-app-footer-text" style={{ fontSize: "12px" }}>
              <b>Description : </b>
            </span>
            <span
              id="menu-des"
              className="text-white scan-app-footer-text"
              style={{ fontSize: "11px" }}
            >
              menu description
            </span>
          </div>
          <div style={{ paddingBottom: "7px" }}>
            <span className="text-white scan-app-footer-text" style={{ fontSize: "12px" }}>
              <b>Price : </b>
            </span>
            <span
              id="menu-price"
              className="text-white scan-app-footer-text"
              style={{ fontSize: "11px" }}
            >
              $34
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aframe;
