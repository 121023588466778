import React, { useState } from "react";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  ListItemText,
} from "@mui/material";

function CollapseList({
  category,
  menus,
  ActiveItem,
  handleActive,
  ActiveChildItme,
}) {
  const [open, setOpen] = useState(false);
  //   const [ActiveItem, setActiveItem] = useState('');
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360 }}>
      <ListItemButton
        onClick={handleClick}
        // onClick={() => handleActive({ type: "main", value: category._id })}
        className={
          ActiveItem?.main == category._id && ActiveItem?.child == false
            ? "scan-app-sidebar-list-active list-button"
            : "scan-app-sidebar-list list-button"
        }
        category-id={category._id}
      >
        <ListItemText primary={category?.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus.map((menu) => {
            const check = menu.category.filter((o) => o.value === category._id);
            if (check.length) {
              return (
                <>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleActive({ type: "child", value: menu._id })
                    }
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    className={
                      ActiveItem?.child == menu._id
                        ? "scan-app-sidebar-list-active list-body"
                        : "scan-app-sidebar-list list-body"
                    }
                    menu-image={menu.image}
                    menu-price={menu.price}
                    menu-description={menu.description}
                    category-id={category._id}
                    menu-scale={menu.scale}
                  >
                    <ListItemText primary={menu?.name} />
                  </ListItemButton>
                </>
              );
            }
          })}
        </List>
      </Collapse>
    </List>
  );
}

export default CollapseList;
