// import logo from "./logo.svg";
// import "./Test.css";
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import burger from "../assets/burger.gltf";
import { useAppServices } from "../hook/services";
import { useEffect, useState } from "react";

function Test() {
  const { id } = useParams();
  const AppService = useAppServices();
  const [Categories, setCategories] = useState([]);

  const getCategories = async () => {
    const { response } = await AppService.category.get({
      query: `resturant_id=647ad6194d8f9203f42fe437`,
    });
    if (response) setCategories(response.data.data);
  };

  const onLoad = () => {
    getCategories(id);
    // alert(id);
  };

  useEffect(onLoad, []);
  return (
    <div className="App">
      <div className="controller">
        <div className="controller_btn">
          <button id="previousBtn">Previous</button>
          <button id="nextBtn">Next</button>
        </div>
      </div>
      <div className="nav_warpper">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
          <div className="container ">
            <a className="navbar-brand" href="#">
              Navbar
            </a>
            <button
              className=" navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              {" "}
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="offcanvas offcanvas-end "
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body bg-transparent">
                <ul
                  className="list-group bg-transparent "
                  id="resturant-category"
                >
                  {Categories.map((category) => (
                    <li className="list-group-item bg-primary rounded text-white mt-3 border-0">
                      kdfj gj
                    </li>
                  ))}
                  <li className="list-group-item bg-transparent text-white mt-3 border-0">
                    A sdf item
                  </li>
                  <li className="list-group-item bg-transparent text-white mt-3 border-0">
                    A sdf item
                  </li>
                  <li className="list-group-item bg-transparent text-white mt-3 border-0">
                    A sdf item
                  </li>
                  <li className="list-group-item bg-transparent text-white mt-3 border-0">
                    And a fifth one
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Test;
