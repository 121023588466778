// import logo from "./logo.svg";
import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Aframe from "./aframe/Aframe";
import Test from "./test/test";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Aframe />}>
          <Route path="aframe" element={<Aframe />} />
          <Route path="aframe/:id" element={<Aframe />} />
        </Route>
        <Route path="test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
